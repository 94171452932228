// Angular
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, LOCALE_ID, NgModule, inject, provideAppInitializer } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import nl from '@angular/common/locales/nl-BE';
import fr from '@angular/common/locales/fr';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, ROUTES } from '@angular/router';
// 3rd-party
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
// local
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { ApiModule, Configuration } from './utils/api';
import {
    isStringPipe,
    findInArrayPipe,
    customCurrencyPipe,
    percentagePipe,
    ArrayPipe,
    callbackPipe,
    SafePipe,
    floatDisplayPipe,
    numberDisplayPipe,
    vatPipe,
    venPipe,
    FileSizePipe,
    TranslatePipe,
    LocalizedDatePipe
} from './utils/pipes';
import { ErrorHandlerService } from './utils/errorHandler';
import { InitService } from './services/init.service';
import { AuthGuardService } from './services/auth-guard.service';
import { resourcePipe, yesNoPipe } from './services/resource.service';
import { MonitoringService } from './services/monitoring.service';
import { ComponentsModule } from './components/components.module';
import { NavigationComponent } from './components/layout/navigation/navigation.component';
import { TranslationsComponent } from './pages/misc/translations/translations.component';
import { UtilsModule } from './utils/utils.module';
import { APP_CONFIG } from '../constants';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { StaticPageWrapperComponent } from './components/layout';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { BuyerGuardService, SampleAuthorityGuardService } from './services';

registerLocaleData(nl);
registerLocaleData(fr);

@NgModule({
    declarations: [
        AppComponent,
        NavigationComponent,
        isStringPipe,
        findInArrayPipe,
        resourcePipe,
        yesNoPipe,
        floatDisplayPipe,
        numberDisplayPipe,
        vatPipe,
        venPipe,
        customCurrencyPipe,
        percentagePipe,
        ArrayPipe,
        callbackPipe,
        FileSizePipe,
        SafePipe,
        TranslationsComponent,
        CookiePolicyComponent,
        StaticPageWrapperComponent,
        TermsOfUseComponent,
        PrivacyPolicyComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        QuillModule.forRoot(),
        CommonModule,
        ComponentsModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            extendedTimeOut: 5000,
            positionClass: 'toast-bottom-right',
            closeButton: true,
            // disableTimeOut: true,
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning'
            }
        }),
        NgxMaskDirective,
        NgxMaskPipe,
        AppRoutingModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule.forRoot(() => {
            return new Configuration({ basePath: '/api' });
        }),
        UtilsModule,
        RecaptchaModule,
        RecaptchaFormsModule
    ],
    providers: [
        { provide: ROUTES, useFactory: configRoutes, deps: [APP_CONFIG], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        provideAppInitializer(() => {
            const initializerFn = initializeApp(inject(InitService));
            return initializerFn();
        }),
        { provide: LOCALE_ID, useValue: 'nl-BE' },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                // V2
                siteKey: '6Ld5YHcjAAAAAO9TLzA6d2I0HpVxj3E1TcZteosf'
            } as RecaptchaSettings
        },
        CurrencyPipe,
        customCurrencyPipe,
        percentagePipe,
        vatPipe,
        venPipe,
        AuthGuardService,
        BuyerGuardService,
        MonitoringService,
        TranslatePipe,
        SampleAuthorityGuardService,
        DatePipe,
        LocalizedDatePipe,
        provideNgxMask(),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {}

export function initializeApp(InitService: InitService) {
    return (): Promise<any> => {
        return InitService.initialize();
    };
}

export function configRoutes(config: any) {
    let routes: Routes = [];

    const portal = config.portal;

    switch (portal) {
        case 'admin':
            routes = [
                {
                    path: '',
                    loadChildren: () => import('src/app/admin-routing.module').then((mod) => mod.AdminRoutingModule)
                }
            ];
            break;
        case 'member':
            routes = [
                {
                    path: '',
                    loadChildren: () => import('src/app/member-routing.module').then((mod) => mod.MemberRoutingModule)
                }
            ];
            break;
    }
    return routes;
}
