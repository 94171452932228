// Angular
import { Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
// services
import { AuthenticationService, ConfigService } from 'src/app/services';
// users
import { User } from '../../../modules/users/models';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    standalone: false
})
export class NavigationComponent implements OnInit {
    collapsed: boolean = false;
    collapsedAnimationDone: boolean = true;
    config: any = null;
    user: User = null;

    settingsOpen: boolean = false;
    settingsActive: boolean = false;

    samplesMemberOpen: boolean = false;
    samplesMemberActive: boolean = false;

    samplesAdminOpen: boolean = false;
    samplesAdminActive: boolean = false;

    constructor(
        private authenticationService: AuthenticationService,
        private configService: ConfigService,
        public router: Router
    ) {
        this.authenticationService.getUser().subscribe((next) => {
            this.user = next;
        });
        this.configService.getNavCollapsed().subscribe((next) => {
            this.collapsed = next;
        });
    }

    ngOnInit(): void {
        if (localStorage.getItem('navigation_is_collapsed') === 'yes') {
            // this.collapsed = true;
            this.configService.setNavCollapsed(true);
        } else {
            // this.collapsed = false;
            this.configService.setNavCollapsed(false);
        }

        this.config = this.configService.getConfig();

        this.openSubNavigations();
        this.listenRouteChangeOpenCloseSubNav();
    }

    private openSubNavigations(): void {
        if (this.menuActive('settings/')) {
            this.settingsOpen = true;
            this.settingsActive = true;
        }
        if (this.menuActive('samplings/')) {
            this.samplesMemberOpen = true;
            this.samplesMemberActive = true;
        }
        if (this.menuActive('samples/')) {
            this.samplesAdminOpen = true;
            this.samplesAdminActive = true;
        }
    }

    private listenRouteChangeOpenCloseSubNav(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                if (event.url.indexOf('/settings') === 0) {
                    this.settingsActive = true;
                    this.settingsOpen = true;
                } else {
                    this.settingsActive = false;
                }

                if (event.url.indexOf('/samplings') === 0) {
                    this.samplesMemberOpen = true;
                    this.samplesMemberActive = true;
                } else {
                    this.samplesMemberActive = false;
                }

                if (event.url.indexOf('/samples') === 0) {
                    this.samplesAdminOpen = true;
                    this.samplesAdminActive = true;
                } else {
                    this.samplesAdminActive = false;
                }
            }
        });
    }

    toggleCollapsed() {
        // this.collapsed = !this.collapsed;
        this.configService.setNavCollapsed(!this.collapsed);
        this.collapsedAnimationDone = false;
        localStorage.setItem('navigation_is_collapsed', this.collapsed ? 'yes' : 'no');
        setTimeout(() => {
            this.collapsedAnimationDone = true;
        }, 180);
    }

    menuActive(menu) {
        return this.router.url.includes(menu);
    }
}
