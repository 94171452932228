import { Component, HostListener, OnInit } from '@angular/core';
import { HelpersService } from 'src/app/services/helpers.service';
import { TranslatorService } from 'src/app/services/translator.service';

@Component({
    selector: 'app-translations',
    templateUrl: './translations.component.html',
    styleUrls: ['./translations.component.scss'],
    standalone: false
})
export class TranslationsComponent implements OnInit {
    strings: any = [];
    ready: boolean = false;
    saving: number[] = [];
    changed: number[] = [];
    FILTERS = {
        term: '',
        empty: false
    };
    languages: string[] = ['nl', 'fr'];
    thWidth: string = '50%';
    helper: any = {};

    constructor(private TranslatorService: TranslatorService, private HelpersService: HelpersService) {}

    ngOnInit(): void {
        this.thWidth = `${Math.round((100 / this.languages.length) * 100) / 100}%`;
        this.TranslatorService.fetchAllTranslations().subscribe((next: any[]) => {
            this.strings = next.map((item) => {
                const fill = {};
                this.languages.forEach((lang) => {
                    fill[lang + '_saved'] = item[lang];
                });
                return { ...item, ...fill };
            });
            this.ready = true;
        });
    }

    indexIsSaving(index) {
        return this.saving.indexOf(index) != -1;
    }

    saveIndex(index) {
        this.saving.push(index);
        const changedIndex = this.changed.indexOf(index);
        if (changedIndex != -1) this.changed.splice(changedIndex, 1);
        let STRING = this.strings.filter((item) => {
            return this.filterStrings(item);
        })[index];
        const FORM = { string: STRING.string };
        this.languages.forEach((lang) => {
            FORM[lang] = STRING[lang];
        });
        this.TranslatorService.updateTranslation(FORM).subscribe((next) => {
            const savingIndex = this.saving.indexOf(index);
            if (savingIndex != -1) this.saving.splice(savingIndex, 1);
            this.languages.forEach((lang) => {
                STRING[lang + '_saved'] = STRING[lang];
            });
        });
    }

    indexIsChanged(index) {
        return this.changed.indexOf(index) != -1;
    }

    changeIndex(index) {
        if (this.changed.indexOf(index) == -1) this.changed.push(index);
    }

    filterStrings = (item: any) => {
        if (this.FILTERS?.term) {
            if (item?.string?.toLowerCase().indexOf(this.FILTERS.term?.toLowerCase()) === -1) {
                return false;
            }
        }
        if (this.FILTERS?.empty) {
            for (let i = 0; i < this.languages.length; i++) {
                const lang = this.languages[i];
                if (item[lang + '_saved']) {
                    return false;
                }
            }
        }
        return true;
    };

    @HostListener('window:scroll', []) onWindowScroll() {
        if (document.body.scrollTop > 120 || document.documentElement.scrollTop > 120) {
            this.helper.showUp = true;
        } else {
            this.helper.showUp = false;
        }
    }

    toTop() {
        this.HelpersService.toTop('top');
    }
}
