import { Injectable, Pipe, PipeTransform } from '@angular/core';
import {BehaviorSubject, Observable, forkJoin, of} from 'rxjs';
import { tap } from 'rxjs/operators';
// import { DefaultService } from '../utils/api';
import { TranslatePipe } from '../utils/pipes';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    private resources$ = new BehaviorSubject<any>([]);
    private admins$ = new BehaviorSubject<any>([]);
    user: any = null;

    constructor(private AuthenticationService: AuthenticationService, private TranslatePipe: TranslatePipe) {
        this.AuthenticationService.getUser().subscribe((next) => {
            this.user = next;
        });
    }
    // constructor(
    //     private DefaultService: DefaultService,
    //     private AuthenticationService: AuthenticationService,
    //     private TranslatePipe: TranslatePipe
    // ) {
    //     this.AuthenticationService.getUser().subscribe((next) => {
    //         this.user = next;
    //     });
    // }

    public init(): Observable<any>[] {
        const observables$: Observable<any>[] = [of(null)];

        // @pj wait for DefaultService
        // const resources$ = this.DefaultService.resourceGetResources().pipe(
        //     tap((next: any) => {
        //         this.resources$.next(next);
        //     })
        // );
        // observables$.push(resources$);
        // const admins$ = this.DefaultService.usersGetUsers(undefined, undefined, undefined, undefined, 'admin').pipe(
        //     tap((next: any) => {
        //         const result = next.data.map((item) => {
        //             return {
        //                 ...item,
        //                 label: `${item.firstname} ${item.lastname}`
        //             };
        //         });
        //         this.admins$.next({ admin: result });
        //     })
        // );
        // observables$.push(admins$);
        return observables$;
    }

    public reload(): void {
        forkJoin(this.init()).subscribe((next) => {});
    }

    public getResource(type: string) {
        let source = this.resources$;
        if (type === 'admin') {
            source = this.admins$;
        }
        const result = source.getValue()[type]?.map((item) => {
            return {
                ...item,
                title: item.label,
                value: item.id || item.code
            };
        });
        if (!result?.length) return [{ title: 'notfound', value: 'notfound' }];
        return result;
    }

    public getResourceById(type: string, id: string) {
        let source = this.resources$;
        if (type === 'admin') {
            source = this.admins$;
        }
        const result = source
            .getValue()
            [type].map((item) => {
                return {
                    ...item,
                    title: item.label,
                    value: item.id || item.code
                };
            })
            .find((item) => {
                return item.id === id;
            });

        return result || { title: 'notfound', value: 'notfound' };
    }

    public getResourceByCode(type: string, code: string) {
        const result = this.resources$
            .getValue()
            [type].map((item) => {
                return {
                    ...item,
                    title: item.label,
                    value: item.id || item.code
                };
            })
            .find((item) => {
                return item?.code?.toString().toLowerCase() === code?.toString().toLowerCase();
            });

        return result || null;
        // return result || { title: 'notfound', value: 'notfound' };
    }

    public getYesNoOptions() {
        return [
            { title: this.TranslatePipe.transform('misc_yes'), value: true },
            { title: this.TranslatePipe.transform('misc_no'), value: false }
        ];
    }

    public getYesNoOption(value) {
        return this.getYesNoOptions().find((item) => {
            return item.value == value;
        });
    }

    public getSearchNames({ pharmaceuticalForm, routeOfAdministration, atcCode }) {
        // return this.DefaultService.resourceGetSearchNames(
        //     pharmaceuticalForm,
        //     routeOfAdministration,
        //     atcCode
        // ).toPromise();
    }

    public getTypeaheadResource(term, type) {
        // return this.DefaultService.resourceGetAutocompleteResource(type, 50, term);
    }

    public getChildActivities(activityCode) {
        return this.getResource('activity').find((item) => {
            return item.code === activityCode;
        })?.child_activities;
    }
}

@Pipe({
    name: 'resource',
    standalone: false
})
export class resourcePipe implements PipeTransform {
    constructor(private ResourceService: ResourceService) {}
    transform(value: any, type: string, identifier: string, property?: string): any {
        if (type === 'child_activity') {
            return (
                this.ResourceService.getChildActivities(identifier)?.find((item) => {
                    return item.id === value;
                })?.name || '-'
            );
        }
        const key = property || 'title';
        if (value && identifier === 'code') {
            return this.ResourceService.getResourceByCode(type, value)[key];
        }
        if (value && identifier === 'id') {
            return this.ResourceService.getResourceById(type, value)[key];
        }
        return '-';
    }
}

@Pipe({
    name: 'yesNo',
    standalone: false
})
export class yesNoPipe implements PipeTransform {
    constructor(private ResourceService: ResourceService) {}
    transform(value: any, strict: boolean): any {
        if (strict && value !== true && value !== false) {
            return '-';
        } else return this.ResourceService.getYesNoOption(value).title;
    }
}
