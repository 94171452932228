// Angular
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// import { User } from 'backend/src/models/basic-models';
// services
import { ConfigService } from 'src/app/services';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    standalone: false
})
export class AvatarComponent implements OnInit, OnChanges {
    @Input() user?: any;
    @Input() image?: string;
    @Input() title?: string;
    @Input() subtitle?: string;
    @Input() size?: number = 32;
    @Input() fontSize?: number = 13;
    @Input() shadowSize?: number;
    @Input() color?: string;
    @Input() typeClass?: string = '';
    @Input() clickable: boolean = false;

    initials: string = '';
    isMember: boolean = false;

    constructor(private configService: ConfigService) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        this.setInitials();
    }

    setInitials() {
        if (this.user) {
            const user = { ...this.user };
            user.firstname = user.firstname || '';
            user.lastname = user.lastname || '';
            this.initials = `${user.firstname?.trim()?.charAt(0)}${user?.lastname?.trim()?.charAt(0)}${
                this.configService.getConfig()?.avatarExtendInitials
                    ? user?.lastname?.trim()?.charAt(user?.lastname?.trim()?.length - 1)
                    : ''
            }`?.toUpperCase();

            this.isMember = this.configService.getConfig().portal === 'member';
        }
    }
}
