// Angular
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.scss'],
    standalone: false
})
export class TermsOfUseComponent implements OnInit {
    languageCode: 'nl' | 'fr' = 'nl';

    constructor(private activatedRoute: ActivatedRoute) {}

    ngOnInit() {
        this.languageCode = this.activatedRoute.snapshot.params['language_code'];
    }

    onSelectLanguageCode(lang: 'nl' | 'fr') {
        window.open(`/${lang}/terms-of-use`, '_self');
    }
}
