// Angular
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RoutesRecognized} from '@angular/router';
// 3rd-party
import { BehaviorSubject } from 'rxjs';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
// local
import { HelpersService, TranslatorService } from './services';
import { TranslatePipe } from './utils/pipes';
import { ConfigService } from './services';
import { AuthenticationService } from './services/authentication.service';
import { MonitoringService } from './services/monitoring.service';
import { filter, map } from 'rxjs/operators';
import * as packageJson from '../../package.json';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: false
})
export class AppComponent implements OnInit {
    ready$ = new BehaviorSubject<boolean>(false);
    firstRouterCycleDone: boolean = false;
    isPublic: boolean;
    busy: boolean = false;
    user: any = null;
    isModalRoute: boolean = false;
    hideNavigation: boolean = false;
    hideFooter: boolean = false;
    previousRoute: ActivatedRouteSnapshot = undefined;

    version: string = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private configService: ConfigService,
        private helpersService: HelpersService,
        private monitoringService: MonitoringService,
        private popoverConfig: NgbPopoverConfig,
        private renderer2: Renderer2,
        private title: Title,
        private translatePipe: TranslatePipe,
        private translatorService: TranslatorService,
        public router: Router
    ) {
        const packageJsonAny: any = packageJson;
        if (this.configService.getConfig().node_env?.toLowerCase() !== 'production') {
            this.version = `${this.configService.getConfig().node_env} ${
                packageJsonAny?.build ||
                packageJsonAny.version ||
                packageJsonAny?.default?.build ||
                packageJsonAny?.default?.version
            } - ${this.configService.getConfig().uptime}`;
        }
    }

    ngOnInit(): void {
        // listen to user
        this.authenticationService.getUser().subscribe((next) => {
            this.user = next;
        });
        // listen to router events
        this.router.events.subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                let route = event.state.root.firstChild;
                route = this.getChildRoute(route);

                if (
                    !route.data.modalRoute &&
                    !this.previousRoute?.data?.modalRoute &&
                    route?.data?.title !== this.previousRoute?.data?.title
                ) {
                    document.documentElement.style.scrollBehavior = 'auto';
                    window.scrollTo(0, 0);
                }

                this.previousRoute = route;

                if (this.firstRouterCycleDone) {
                    this.handleRouteChange(route);
                } else {
                    this.firstRouterCycleDone = true;
                    if (route.data.public) {
                        this.initPublicRoute(route.params['language_code']);
                    } else {
                        this.initPrivateRoute();
                    }
                }
            }
        });
        this.helpersService.getBusy().subscribe((next) => {
            this.busy = next;
        });
        this.configService.getModalRoute().subscribe((next) => {
            this.isModalRoute = next;
        });
        this.configService.getHideNavigation().subscribe((next) => {
            this.hideNavigation = next;
        });
        this.configService.getHideFooter().subscribe((next) => {
            this.hideFooter = next;
        });
        this.popoverConfig.animation = false;
    }

    public handleRouteChange(route) {
        let title = this.translatePipe.transform('meta_title');
        let routeTitle = route?.data?.title;

        if (routeTitle) {
            let pre = '';
            let portalString = '';
            if (this.user?.portal) {
                portalString = `${this.user.portal}_`;
            }
            pre += this.translatePipe.transform(`meta_title_${portalString}${routeTitle}`);
            title = `${pre} | ${title}`;
        }
        if (routeTitle?.indexOf('async') === -1) this.title.setTitle(title);

        // hide navigation
        if (route?.data?.hideNavigation) {
            this.configService.setHideNavigation(true);
        } else this.configService.setHideNavigation(false);

        // hide footer
        if (route?.data?.hideFooter) {
            this.configService.setHideFooter(true);
        } else this.configService.setHideFooter(false);

        if (route?.data?.captcha) {
            document.body.classList.add('show-captcha');
            this.configService.setHideCaptcha(false);
        } else {
            document.body.classList.remove('show-captcha');
            this.configService.setHideCaptcha(true);
        }

        // handle modal routes
        if (route?.data?.modalRoute) {
            this.renderer2.addClass(document.body, 'modal-open');
            this.renderer2.addClass(document.body, 'modal-open--route');
            this.configService.setModalRoute(true);
        } else if (document.body.classList.contains('modal-open--route')) {
            this.configService.setModalRoute(false);
            this.renderer2.removeClass(document.body, 'modal-open');
            this.renderer2.removeClass(document.body, 'modal-open--route');
        }
    }

    getChildRoute(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
        while (route?.firstChild) {
            route = route.firstChild;
        }

        return route;
    }

    public getReady() {
        return this.ready$.asObservable();
    }

    private setReady(ready: boolean) {
        this.ready$.next(ready);
    }

    initPublicRoute(languageCode: string) {
        this.translatorService.init(languageCode).subscribe(() => {
            this.setReady(true);
            const route = this.getChildRoute(this.activatedRoute.snapshot);
            this.handleRouteChange(route);
        });
    }

    initPrivateRoute() {
        this.authenticationService.fetchUser().subscribe((next) => {
            this.translatorService.init(next.language).subscribe(() => {
                this.setReady(true);
                const route = this.getChildRoute(this.activatedRoute.snapshot);
                this.handleRouteChange(route);
            });
        });
    }
}
